<template>
  <Layout>
    <section class="csms-terms">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="terms-search-title">{{ $t('terms.title') }}</label>
                      <input type="text" class="form-control" id="terms-search-title"
                             v-model="searchForm.title" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="terms-search-system">{{ $t('terms.appCsmsType') }}</label>
                      <multiselect id="terms-search-system"
                                   v-model="searchForm.appCsmsType"
                                   label="text" track-by="value"
                                   :options="systemTypeOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="terms-search-type">{{ $t('terms.termType') }}</label>
                      <multiselect id="terms-search-type"
                                   v-model="searchForm.termType"
                                   label="text" track-by="value"
                                   :options="usedTypeOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('terms.noTerms')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`terms.${data.column}`) }}</span>
                  </template>

                  <template #cell(checkbox)="data">
                    <input type="checkbox" class="form-check-input" v-model="selectedTerm" :value="data.item.id">
                  </template>
                  <template #cell(termType)="data">
                    {{ $_formatter.termsTypeName(data.item.termType) }}
                  </template>
                  <template #cell(title)="data">
                    <a href="#" @click="viewModal(data.item.id)">{{ $_utils.textLengthOverCut(data.item.title, 40, null) }}</a>
                  </template>
                  <template #cell(agreeOption)="data">
                    {{ $_formatter.agreeOptionName(data.item.agreeOption) }}
                  </template>
                  <template #cell(isPublish)="data">
                    {{ data.item.isPublish ? $t('common.open') : $t('common.private') }}
                  </template>
                  <template #cell(etc)="data">
                    <button class="btn btn-sm btn-warning w-sm" @click="editModal(data.item.id)">{{ $t('btn.edit') }}</button>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <!-- Bottom Button Start -->
              <div class="row ion-mb-mt-100">
                <div class="col-xl-12">
                  <div class="float-start">
                    <b-button variant="danger" @click="del">
                      <i class="uil uil-minus me-2"></i>
                      <span>{{ $t('btn.delete') }}</span>
                    </b-button>
                  </div>
                  <div class="float-end">
                    <b-button variant="success" @click="create">
                      <i class="uil uil-plus me-2"></i>
                      <span>{{ $t('btn.create') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <!-- Bottom Button End -->

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <create-modal ref="termsCreateModal" @callback="getItems" />
      <view-modal ref="termsViewModal" :selected-id="selectedId" @callback="getItems" />
      <edit-modal ref="termsEditModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import CreateModal from '@/views/pages/terms/TermsCreateModal.vue';
import ViewModal from '@/views/pages/terms/TermsViewModal.vue';
import EditModal from '@/views/pages/terms/TermsEditModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, CreateModal, ViewModal, EditModal
  },
  data() {
    return {
      title: 'termsPolicy',
      selectedId: null,
      selectedTerm: [],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        title: null,
        appCsmsType: { text: i18n.t('common.total'), value: null },
        termType: { text: i18n.t('common.total'), value: null },
      },
      fields: [
        { key: 'checkbox', label: '', class: 'text-center' },
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'appCsmsType', label: '시스템', class: 'text-center' },
        { key: 'termType', label: '유형', class: 'text-center' },
        { key: 'title', label: '제목', class: 'text-center' },
        { key: 'agreeOption', label: '동의 여부', class: 'text-center' },
        { key: 'isPublish', label: '공개 여부', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
        { key: 'updated', label: '수정일', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null,
      systemTypeOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: 'APP', value: 'APP' },
        { text: 'CSMS', value: 'CSMS' },
      ],
      usedTypeOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('terms.typeMember'), value: 'Member' },
        { text: i18n.t('terms.typeCard'), value: 'Card' },
        { text: i18n.t('terms.typePlan'), value: 'Plan' },
        { text: i18n.t('terms.typeQuit'), value: 'Quit' },
      ],
    }
  },
  mounted() {
    const vm = this;
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    reset() {
      const vm = this;

      vm.selectedId = null;
      vm.searchForm = {
        title: null,
        appCsmsType: { text: i18n.t('common.total'), value: null },
        termType: { text: i18n.t('common.total'), value: null },
      };
      vm.searchConditions = [];

      vm.getItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.title)) {
        vm.searchConditions.push({ searchKey: 'title', searchValue: vm.searchForm.title });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.appCsmsType.value)) {
        vm.searchConditions.push({ searchKey: 'appCsmsType', searchValue: vm.searchForm.appCsmsType.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.termType.value)) {
        vm.searchConditions.push({ searchKey: 'termType', searchValue: vm.searchForm.termType.value });
      }

      vm.pagination.currentPage = 1;
      vm.getItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.title)
          && this.$_utils.isEmpty(vm.searchForm.appCsmsType.value)
          && this.$_utils.isEmpty(vm.searchForm.termType.value)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.title)) {
          vm.searchConditions.push({ searchKey: 'title', searchValue: vm.searchForm.title });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.appCsmsType.value)) {
          vm.searchConditions.push({ searchKey: 'appCsmsType', searchValue: vm.searchForm.appCsmsType.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.termType.value)) {
          vm.searchConditions.push({ searchKey: 'termType', searchValue: vm.searchForm.termType.value });
        }

        vm.pagination.currentPage = 1;
        vm.getItems();
      }*/
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/TermsPolicy/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Terms List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getItems();
      }
    },
    create() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.termsCreateModal.show();
      });
    },
    viewModal(id) {
      const vm = this;
      vm.selectedId = id;

      this.$nextTick(() => {
        this.$refs.termsViewModal.show();
      });
    },
    editModal(id) {
      const vm = this;
      vm.selectedId = id;

      this.$nextTick(() => {
        this.$refs.termsEditModal.show();
      });
    },
    del() {
      const vm = this;

      if (vm.selectedTerm.length < 1) {
        Swal.fire({
          icon: 'error',
          title: i18n.t('alert.selectTerms'),
          showConfirmButton: false,
          timer: 3000,
        });
        return false;
      } else {
        Swal.fire({
          title: i18n.t('alert.confirmDelete'),
          showCancelButton: true,
          confirmButtonText: i18n.t('btn.ok'),
          cancelButtonText: i18n.t('btn.cancel')
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              for (const item of vm.selectedTerm) {
                let _url = `/onmapi/view/TermsPolicy/delete/${item}`;

                try {
                  await http.delete(_url);
                  console.info('Terms Delete Success !!', item);
                } catch (err) {
                  console.error('Terms Delete Error !!', err);
                }
              }

              vm.selectedTerm = [];
              await vm.getItems();

              await Swal.fire({
                icon: 'info',
                title: i18n.t('alert.deleted'),
                confirmButtonText: i18n.t('btn.close')
              });
            })();
          }
        })
      }
    }
  }
}
</script>
