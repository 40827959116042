<template>
  <b-modal header-class="ion-bg-modal-header" size="xl"
           centered scrollable no-close-on-backdrop
           v-model="visible">

    <template #modal-header>
      <div class="fw-bold">{{ $t('terms.termsTitle') }}</div>
    </template>

    <div class="row" v-if="!$_utils.isEmpty(item)">
      <div class="col-xl-12">
        <div class="card shadow-none mb-0">
          <div class="card-body">

            <!-- Title Start -->
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                <div class="avatar-sm">
                  <div class="avatar-title bg-light text-primary rounded-circle font-size-18">
                    <i class="mdi mdi-file-document-edit-outline"></i>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1 overflow-hidden mb-1">
                <h5 class="font-size-18 fw-bold">
                  <span class="badge rounded-pill me-2" :class="item.isPublish ? 'badge-soft-primary' : 'badge-soft-secondary'">
                    {{ item.isPublish ? $t('common.open') : $t('common.private') }}
                  </span>
                  <span>{{ item.title }}</span>
                </h5>
                <span class="font-size-14 text-muted">{{ $t('terms.created') }} : {{ item.created }}</span>
                <span class="text-primary ps-2 pe-2">|</span>
                <span class="font-size-14 text-muted">{{ $t('terms.updated') }} : {{ item.updated }}</span>
              </div>
            </div>
            <!-- Title End -->

            <hr />

            <!-- Contents Start -->
            <div v-html="`${item.contents}`" style="overflow: auto; max-height: 600px; min-height: 200px" />
            <!-- Contents End -->

          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100 text-end">
        <b-button variant="secondary" class="w-xs p-1" @click="close">{{ $t('btn.close') }}</b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
import { http } from '@/auth-api';

export default {
  props: {
    selectedId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      item: null
    }
  },
  methods: {
    show() {
      const vm = this;

      vm.getItem();
      vm.visible = true;
    },
    getItem() {
      const vm = this;
      const _url = `/onmapi/view/TermsPolicy/retrieve/${this.selectedId}`;

      http.get(_url).then((res) => {
        vm.item = res.data.item;
      }).catch((err) => {
        console.error('Terms Retrieve Error !!', err)
      });
    },
    close() {
      const vm = this;

      vm.item = null;
      this.$emit('callback');
      vm.visible = false;
    }
  }
}
</script>
